// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/partner/main/signup-section/icon-close.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/partner/main/signup-section/icon-modal-02.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-ekb{font-family:Montserrat,Arial,sans-serif;color:#fff}.modal-ekb .modal-close{display:none!important;z-index:2;position:absolute;width:26px;height:26px;right:23px;top:23px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;cursor:pointer}.modal-ekb__wrapper{padding:30px 50px 50px;position:relative}@media(max-width:6400px){.modal-ekb__wrapper{padding:15px 24px 32px}}.modal-ekb__wrapper:after{content:\"\";right:0;bottom:20px;transform:translate(50%);position:absolute;width:159px;height:184px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%/100% 100%}@media(max-width:960px){.modal-ekb__wrapper:after{width:67px;height:69px;right:40px}}.modal-ekb__title{font-size:50px;font-weight:700;margin-bottom:1em}@media(max-width:960px){.modal-ekb__title{font-size:34px}}@media(max-width:640px){.modal-ekb__title{font-size:24px}}.modal-ekb__list li{font-size:24px;line-height:1.5}@media(max-width:960px){.modal-ekb__list li{font-size:18px}}@media(max-width:640px){.modal-ekb__list li{font-size:14px}}.modal-ekb__list li:not(:last-child){margin-bottom:.4em}@media(max-width:960px){.modal-ekb__list li:not(:last-child){margin-bottom:.8em}}.modal-ekb__list li a{color:#fff;display:inline-block;border-bottom:1px solid #fff}.modal-ekb__list li a:hover{border-bottom-color:transparent}.modal-ekb .vm--modal{background-color:#602a7a}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
